import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import {SStoreData} from "@/lib/store/model/abstract/SStoreData";
import {
    IsWithinXInstancesOfTypeValidator
} from "@/lib/store/validator/validator/annotation/IsWithinXInstancesOfTypeValidator";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";
import {SSpatial2DBoundingBox} from "@/store/Spatial2DAnnotation/model/SSpatial2DBoundingBox";
import {SSpatial2DDotAnnotation} from "@/store/Spatial2DAnnotation/model/SSpatial2DDotAnnotation";
import EncapsulatedValidator from "@/lib/store/validator/center/abstract/type/EncapsulatedValidator";
import {IsNumberBetweenValidator} from "@/lib/store/validator/validator/common/IsNumberBetweenValidator";


export class Spatial2DBoundingBoxValidatorCenter<D extends SSpatial2DBoundingBox> extends AbstractValidatorCenter<D>
{
    constructor(entity: D, annotationTypeToValidate: string|void, maxNumberOfInstancesForType: number|void)
    {
        super(entity);

        if (
            annotationTypeToValidate
            && maxNumberOfInstancesForType
        )
        {
            this.encapsulatedValidators.push({
                validator: new IsWithinXInstancesOfTypeValidator({onInvalidDo: ON_INVALID_DO.TRANSFORM, x: maxNumberOfInstancesForType, type: annotationTypeToValidate}),
                getter: (entity: D) => entity.annotations,
                setter: (entity: D, annotations: SSpatial2DDotAnnotation[]) => {
                    entity.annotations = annotations;
                }
            })
        }
    }

    protected encapsulatedValidators: EncapsulatedValidator[] = [
        {
            validator: new IsNumberBetweenValidator({onInvalidDo: ON_INVALID_DO.THROW, min: 0, max: 100}),
            getter: (entity: D) => entity.top
        },
        {
            validator: new IsNumberBetweenValidator({onInvalidDo: ON_INVALID_DO.THROW, min: 0, max: 100}),
            getter: (entity: D) => entity.left
        },
    ];
}