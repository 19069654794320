import {getModule} from "vuex-module-decorators";
import store from "@/lib/store";
import {AnnotationType} from "@/data/annotation/enum/AnnotationType";
import FileHelper from "@/lib/common/helpers/FileHelper";
import {removeExtensionFromFilename} from "@/lib/common/utilities/StringUtilities";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";
import {MediasStoreManagerBuilder} from "@/lib/store/manager/media/MediasStoreManager";
import {SImageData} from "@/lib/store/model/media/SImageData";
import {AnnotatableImagesStore} from "@/store/Spatial2DAnnotation/holder/AnnotatableImagesStore";
import {SAnnotatableImageData} from "@/store/Spatial2DAnnotation/model/SAnnotatableImageData";
import {SSpatial2DBoundingBox} from "@/store/Spatial2DAnnotation/model/SSpatial2DBoundingBox";
import {SSpatial2DDotAnnotation} from "@/store/Spatial2DAnnotation/model/SSpatial2DDotAnnotation";
import {
  BoxBoundedAnnotatedImageValidatorCenter
} from "@/store/Spatial2DAnnotation/validator/center/BoxBoundedAnnotatedImageValidatorCenter";
import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import {
  Spatial2DBoundingBoxManager
} from "@/store/Spatial2DAnnotation/manager/subManager/Spatial2DBoundingBoxManager";
import {
  AnnotatableBodyPartDisplayParamsStoreManager
} from "@/store/Spatial2DAnnotation/manager/AnnotatableBodyPartDisplayParamsStoreManager";

export class AnnotatableImagesStoreManagerBuilder extends AbstractStoreManagerBuilder<AnnotatableImagesStore<SAnnotatableImageData>, SAnnotatableImageData>
{
  protected _store: AnnotatableImagesStore<SAnnotatableImageData> = getModule(AnnotatableImagesStore, store);

  protected getValidatorCenterForEntity = (entity: SAnnotatableImageData): AbstractValidatorCenter<SAnnotatableImageData> => {
    return new BoxBoundedAnnotatedImageValidatorCenter(entity);
  };

  // ------------------------------
  //  Current cat image and its bounding box
  // ------------------------------

  public setCurrentAnnotatableImage = (currentAnnotatableImage: SAnnotatableImageData) => {
    this._store.setCurrentAnnotatableImage(currentAnnotatableImage.instanceId);
  }
  public setCurrentBoundingBox = (currentBoundingBox: SSpatial2DBoundingBox) =>
  {
    this._store.setCurrentBoundingBox(currentBoundingBox.instanceId);
    AnnotatableBodyPartDisplayParamsStoreManager.resetBaseOnBoundingBox(currentBoundingBox);
  }

  protected getCheckedCurrentAnnotatableImage = () => {
    if (typeof this.store.currentAnnotatableImage === "undefined")
      throw "this._currentAnnotatableImage shouldn't be undefined"

    return this.store.currentAnnotatableImage;
  }
  protected getCheckedCurrentBoundingBox = () => {
    if (typeof this.store.currentBoundingBox === "undefined")
      throw "this._currentBoundingBox shouldn't be undefined"

    return this.store.currentBoundingBox;
  }

  // ------------------------------
  // Add
  // ------------------------------

  public addEmpty(file: File, defaultInstanceId: string|undefined = undefined)
  {
    return this.add({
      instanceId: AbstractStoreManagerBuilder.createInstanceId(defaultInstanceId),
      image: MediasStoreManagerBuilder.createMediaData(file),
      boundingBoxes: [],
    });
  }

  public async addFromFile(file: File)
  {
    const json = await FileHelper.readAsText(file);
    const data = JSON.parse(json) as SSpatial2DBoundingBox[];
    const imageName = removeExtensionFromFilename(file.name);

    const targetEntries = this._store.entries.filter(entry => removeExtensionFromFilename(entry.image.name) === imageName);

    if (targetEntries.length === 0)
    {
      alert("No cat image of name '" + imageName + "' is loaded. Provided data ignored.")
      return;
    }

    const targetEntry = targetEntries[0];

    const result = this.validate(targetEntry);

    const finalEntry: SAnnotatableImageData = result.transformedEntity as SAnnotatableImageData;

    data.forEach(boundingBox => {
      AnnotatableImagesStoreManager.addBoundingBox(finalEntry.instanceId, boundingBox);
    })
  }

  public addBoundingBox(annotatableImageInstanceId: string, boundingBox: SSpatial2DBoundingBox)
  {
    const entry = this._store.byInstanceId(annotatableImageInstanceId);

    if (entry) {
      this.store.addBoundingBox({
        boundingBox: boundingBox,
        annotatableImage: entry
      })
    }
  }

  public addBoundingBoxForCurrent(boundingBox: SSpatial2DBoundingBox)
  {
    this.store.addBoundingBox({
      boundingBox: boundingBox,
      annotatableImage: this.getCheckedCurrentAnnotatableImage()
    })
  }

  public spawnEmptyBoundingBox()
  {
    const spatial2DBoundingBoxManager = new Spatial2DBoundingBoxManager();

    const result = spatial2DBoundingBoxManager.tryAddNew(AnnotationType.MAMMAL);

    this.setCurrentBoundingBox(result);
  }

  // ------------------------------
  //  Set
  // ------------------------------

  public setCurrentAnnotatableImageFromImageData(imageData: SImageData)
  {
    this._store.setCurrentAnnotatableImage(this._store.entries.filter(entry => entry.image.instanceId === imageData.instanceId)[0].instanceId);
  }

  public setBoundingBoxDimensions(top: number, left: number, width: number, height: number)
  {
    this._store.setCurrentBoundingBoxDimensions({
      top: top, left: left, width: width, height: height
    })
  }

  public setCurrentBoundingBoxAnnotations(annotations: SSpatial2DDotAnnotation[])
  {
    this._store.setCurrentBoundingBoxAnnotations(annotations);
  }

  public setAnnotations(annotations: SSpatial2DDotAnnotation[])
  {
    const annotatableImageInstanceId = this.getCheckedCurrentAnnotatableImage().instanceId;
    const boundingBoxInstanceId = this.getCheckedCurrentBoundingBox().instanceId;

    const annotatableImage = this.getByInstanceId(annotatableImageInstanceId);

    const boundingBox = annotatableImage.boundingBoxes.filter(boundingBox => boundingBox.instanceId === boundingBoxInstanceId);

    if (boundingBox.length === 1)
    {
      boundingBox[0].annotations = annotations;

      this.setByInstanceId(annotatableImageInstanceId, annotatableImage);
    }
    else
    {
      throw "Can't add annotations to bounding box of instanceId "+ boundingBoxInstanceId +" : bounding box doesn't exist for annotatableImage of instanceId "+ annotatableImageInstanceId;
    }
  }

  // ------------------------------
  //  Delete
  // ------------------------------

  public removeBoundingBox(boundingBox: SSpatial2DBoundingBox)
  {
    this._store.resetCurrentBoundingBox();

    this._store.removeBoundingBox(boundingBox.instanceId);
  }

  public removeDotAnnotationOfInstanceIdAndTypeFromCurrentBoundingBox(instanceId: string, type: string)
  {
    this.store.removeDotAnnotationOfInstanceIdFromCurrentBoundingBox(instanceId);

    AnnotatableBodyPartDisplayParamsStoreManager.store.setIsSpawnedBasedOnValue({value: type, isSpawned: false});
    AnnotatableBodyPartDisplayParamsStoreManager.store.resetSelected();
    AnnotatableBodyPartDisplayParamsStoreManager.store.switchToNextSelected();
  }

  // ------------------------------
  //  Communications with the store
  // ------------------------------

  public getByImageInstanceId(imageInstanceId: string)
  {
    const compatibleImages = this._store.entries.filter(annotatableImageData => annotatableImageData.image.instanceId === imageInstanceId);

    if (compatibleImages.length !== 1)
      throw "Logical Error : No or multiple entries for the imageInstanceId : " + imageInstanceId;

    return compatibleImages[0];
  }

  protected getByInstanceId(instanceId: string)
  {
    return this._store.byInstanceId(instanceId, true) as SAnnotatableImageData
  }

  protected setByInstanceId(instanceId: string, annotatableImage: SAnnotatableImageData)
  {
    this._store.setByInstanceId({
      entry: annotatableImage,
      instanceId: instanceId
    });
  }
}

export const AnnotatableImagesStoreManager = new AnnotatableImagesStoreManagerBuilder();