import {Module, Mutation} from 'vuex-module-decorators'
import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";
import {SAnnotatableImageData} from "@/store/Spatial2DAnnotation/model/SAnnotatableImageData";
import {SSpatial2DDotAnnotation} from "@/store/Spatial2DAnnotation/model/SSpatial2DDotAnnotation";
import {SSpatial2DBoundingBox} from "@/store/Spatial2DAnnotation/model/SSpatial2DBoundingBox";

@Module({
  name: "AnnotatableImagesStore",
  namespaced: true,
  stateFactory: true
})

export class AnnotatableImagesStore<D extends SAnnotatableImageData> extends AbstractStore<D>
{
  // ------------------------------
  //  General
  // ------------------------------

  get annotated()
  {
    return this._entries.filter(entry => entry.boundingBoxes.length);
  }

  // ------------------------------
  //  Currently selected
  // ------------------------------

  get currentAnnotatableImage()
  {
    const currentAnnotatableImage = this._entries.filter(entry => entry.isCurrent);

    return currentAnnotatableImage.length ? currentAnnotatableImage[0] : undefined;
  }

  get currentBoundingBox()
  {
    const currentAnnotatableImage = this._entries.filter(entry => entry.isCurrent);
    const currentBoundingBox =  currentAnnotatableImage && currentAnnotatableImage.length ? currentAnnotatableImage[0].boundingBoxes.filter(boundingBox => boundingBox.isCurrent) : undefined;

    return currentBoundingBox && currentBoundingBox.length ? currentBoundingBox[0] : undefined;
  }

  @Mutation
  setCurrentAnnotatableImage(annotatableImageInstanceId: string)
  {
    this._entries.map(entry => entry.isCurrent = entry.instanceId === annotatableImageInstanceId);
  }

  @Mutation
  setCurrentBoundingBox(boundingBoxInstanceId: string)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.boundingBoxes.map(boundingBox => boundingBox.isCurrent = boundingBox.instanceId === boundingBoxInstanceId)
    );
  }

  @Mutation
  setCurrentBoundingBoxDimensions({top, left, width, height}: {top: number, left: number, width: number, height: number})
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.boundingBoxes.filter(boundingBox => boundingBox.isCurrent).map(boundingBox => {
          boundingBox.top = top;
          boundingBox.left = left;
          boundingBox.width = width;
          boundingBox.height = height;
        })
    );
  }

  @Mutation
  setCurrentBoundingBoxAnnotations(annotations: SSpatial2DDotAnnotation[])
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.boundingBoxes.filter(boundingBox => boundingBox.isCurrent).map(boundingBox => {
          boundingBox.annotations = annotations;
        })
    );
  }

  @Mutation
  removeDotAnnotationOfTypeFromCurrentBoundingBox(type: string)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.boundingBoxes.filter(boundingBox => boundingBox.isCurrent).map(boundingBox => {
          boundingBox.annotations = boundingBox.annotations.filter(annotation => annotation.type !== type);
        })
    );
  }

  @Mutation
  removeDotAnnotationOfInstanceIdFromCurrentBoundingBox(instanceId: string)
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.boundingBoxes.filter(boundingBox => boundingBox.isCurrent).map(boundingBox => {
          boundingBox.annotations = boundingBox.annotations.filter(annotation => annotation.instanceId !== instanceId);
        })
    );
  }

  @Mutation
  resetCurrentAnnotatableImage()
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => {
          entry.boundingBoxes = [];
        }
    );
  }

  @Mutation
  resetCurrentBoundingBox()
  {
    this._entries.filter(entry => entry.isCurrent).map(
        entry => entry.boundingBoxes.map(boundingBox => boundingBox.isCurrent = false)
    );
  }

  // ------------------------------
  //  Bounding boxes
  // ------------------------------

  get boundingBoxByInstanceId()
  {
    const _this = this;

    return function (annotatableImageInstanceId: string, boundingBoxInstanceId: string): SSpatial2DBoundingBox
    {
      const entry = _this._entries.filter(entry => entry.instanceId === annotatableImageInstanceId);
      const boundingBox = entry.length ? entry[0].boundingBoxes.filter(boundingBox => boundingBox.instanceId === boundingBoxInstanceId) : [];

      const result = boundingBox ? boundingBox[0] : undefined;

      if (typeof result === "undefined")
        throw "Fatal error : Can't find boundingBox of instanceId "+ boundingBoxInstanceId +" for annotatableImage of instanceId "+ annotatableImageInstanceId;

      return result;
    }
  }

  @Mutation
  addBoundingBox({annotatableImage, boundingBox}: {annotatableImage: SAnnotatableImageData, boundingBox: SSpatial2DBoundingBox})
  {
    annotatableImage.boundingBoxes.push(boundingBox);
  }

  @Mutation
  removeBoundingBox(boundingBoxInstanceId: string)
  {
    this._entries.map(entry => {
      entry.boundingBoxes = entry.boundingBoxes.filter(boundingBox => boundingBox.instanceId !== boundingBoxInstanceId);
    });
  }

  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(AbstractStore);
    // Copy parent getters to child
    Object.assign(AnnotatableImagesStore.getters, base.getters);
    Object.assign(AnnotatableImagesStore.mutations, base.mutations);
  }
}