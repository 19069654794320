interface onKonami { (): void }

class KonamiBuilder
{
    protected pattern = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    protected current = 0;

    public start (callback: onKonami)
    {
        const _this = this;

        const keyHandler = function (event: KeyboardEvent) {

            // If the key isn't in the pattern, or isn't the current key in the pattern, reset
            if (_this.pattern.indexOf(event.key) < 0 || event.key !== _this.pattern[_this.current]) {
                _this.current = 0;
                return;
            }

            // Update how much of the _this.pattern is complete
            _this.current++;

            // If complete, alert and reset
            if (_this.pattern.length === _this.current) {
                _this.current = 0;
                callback();
            }
        };

        document.addEventListener('keydown', keyHandler, false);
    }
}

export const Konami = new KonamiBuilder();