export default class UserManualData
{
  public static messages = [
    {
      title: 'Concept',
      content: 'First, load images of mammals. Then, spawn a bounding box and adjust it so one mammal just fits in. Then, with your mouse, click inside the bounding box to indicate its body parts. Add one bounding box per mammal and continue. Finally, download your annotations using the "Download JSON" button on the bottom of the screen. You can also load your annotations on unannotated images.',
    },
    {
      title: 'Adding a bounding box',
      content: 'Press the <span>Space Bar</span> or click on「Add bounding box」button',
    },
    {
      title: 'Adding a body part annotation',
      content: '<span>Left Click</span> inside the selected bounding box to add a body part annotation. This will make you switch to the next spawnable body part annotation if there is any left.',
    },
    {
      title: 'Removing a body part annotation',
      content: 'Press <span>Ctrl + Right Click</span> on the body part annotation you wish to remove.',
    },
    {
      title: 'Switching to the previous spawnable body part annotation',
      content: '<span>Scroll Up</span>.',
    },
    {
      title: 'Switching to the next spawnable body part annotation',
      content: '<span>Scroll Down</span>.',
    },
    {
      title: 'Remove all bounding boxes and their annotations from the current image',
      content: 'Press the <span>Escape Key</span>',
    },
  ]
}

