import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import EncapsulatedValidator from "@/lib/store/validator/center/abstract/type/EncapsulatedValidator";
import {IsNumberBetweenValidator} from "@/lib/store/validator/validator/common/IsNumberBetweenValidator";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";
import {SSpatial2DDotAnnotation} from "@/store/Spatial2DAnnotation/model/SSpatial2DDotAnnotation";

export class Spatial2DDotAnnotationValidatorCenter extends AbstractValidatorCenter<SSpatial2DDotAnnotation>
{
    protected encapsulatedValidators: EncapsulatedValidator[] = [
        {
            validator: new IsNumberBetweenValidator({onInvalidDo: ON_INVALID_DO.THROW, min: 0, max: 100}),
            getter: (entity: SSpatial2DDotAnnotation) => entity.top
        },
        {
            validator: new IsNumberBetweenValidator({onInvalidDo: ON_INVALID_DO.THROW, min: 0, max: 100}),
            getter: (entity: SSpatial2DDotAnnotation) => entity.left
        },
    ];
}