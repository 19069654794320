import {AbstractValidator} from "@/lib/store/validator/validator/abstract/AbstractValidator";
import {SAnnotation} from "@/lib/store/model/annotation/SAnnotation";
import {ON_INVALID_DO} from "@/lib/store/validator/center/abstract/enum/ON_INVALID_DO";

/**
 * Validates if among an array of Annotation, we have at most x of a given type.
 *
 * (if onInvalidDo === ON_INVALID_DO.TRANSFORM)
 * Transform : removes the first annotations of the same type, so that we end up with x annotations.
 */
export class IsWithinXInstancesOfTypeValidator extends AbstractValidator<SAnnotation[]>
{
    protected x: number;
    protected type: string;

    protected nbInstances: number = 0;

    constructor({onInvalidDo, x, type}: {onInvalidDo: ON_INVALID_DO, x: number, type: string})
    {
        super(onInvalidDo);

        this.x = x;
        this.type = type;
    }

    protected isValid(annotations: SAnnotation[]): boolean
    {
        this.nbInstances = annotations.filter(annotation => annotation.type === this.type).length;

        return this.nbInstances <= this.x;
    }

    /**
     * If set as TRANSFORM, we're keeping only the last x annotations in the list.
     *
     * @param annotations
     */
    public modelTransformer = (annotations: SAnnotation[]) =>
    {
        const keptAnnotations: SAnnotation[] = [];

        let removedInstances: number = 0

        annotations.forEach(annotation =>
        {
            if (
                annotation.type === this.type
                && this.nbInstances > removedInstances + this.x
            )
            {
                removedInstances++;
            }
            else
            {
                keptAnnotations.push(annotation);
            }
        });

        return keptAnnotations;
    }
}