import {DefaultAnnotatableBodyPart} from "@/data/annotation/enum/DefaultAnnotatableBodyPart";
import {AnnotatableBodyPartParams} from "@/data/annotation/type/AnnotatableBodyPartParams";
import {BoundingBoxShortcutType} from "@/components/frame/image/annotation/enum/BoundingBoxShortcutType";

export const defaultAnnotatableBodyPartsDisplayParams: AnnotatableBodyPartParams[] = [
    {
        value: DefaultAnnotatableBodyPart["Mouse"],
        colorCode: "#1c5dd0",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Head"],
                colorCode: "#5296da"
            }
        ]
    },

    {
        value: DefaultAnnotatableBodyPart["Head"],
        colorCode: "#1c5dd0",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Mouse"],
                colorCode: "#5296da"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Cervical Spine"],
                colorCode: "#5296da"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Cervical Spine"],
        colorCode: "#1c5dd0",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Head"],
                colorCode: "#5296da"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Pelvis"],
                colorCode: "#5296da"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Shoulder"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Shoulder"],
                colorCode: "#57d028"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Pelvis"],
        colorCode: "#1c5dd0",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Cervical Spine"],
                colorCode: "#5296da"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Waist"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Waist"],
                colorCode: "#57d028"
            },
        ]
    },

    {
        value: DefaultAnnotatableBodyPart["Right Shoulder"],
        colorCode: "#ea00df",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Cervical Spine"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Elbow"],
                colorCode: "#c44bd2"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Right Elbow"],
        colorCode: "#ea00df",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Shoulder"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Wrist"],
                colorCode: "#c44bd2"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Right Wrist"],
        colorCode: "#a847d3",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Elbow"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Finger"],
                colorCode: "#c44bd2"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Right Finger"],
        colorCode: "#ea00df",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Wrist"],
                colorCode: "#c44bd2"
            },
        ]
    },

    {
        value: DefaultAnnotatableBodyPart["Left Shoulder"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Cervical Spine"],
                colorCode: "#57d028"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Elbow"],
                colorCode: "#57d028"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Left Elbow"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Shoulder"],
                colorCode: "#57d028"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Wrist"],
                colorCode: "#57d028"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Left Wrist"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Elbow"],
                colorCode: "#57d028"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Finger"],
                colorCode: "#57d028"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Left Finger"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Wrist"],
                colorCode: "#57d028"
            },
        ]
    },

    {
        value: DefaultAnnotatableBodyPart["Right Waist"],
        colorCode: "#ea00df",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Pelvis"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Knee"],
                colorCode: "#c44bd2"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Right Knee"],
        colorCode: "#ea00df",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Waist"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Heel"],
                colorCode: "#c44bd2"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Right Heel"],
        colorCode: "#ea00df",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Knee"],
                colorCode: "#c44bd2"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Toe"],
                colorCode: "#c44bd2"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Right Toe"],
        colorCode: "#ea00df",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Right Heel"],
                colorCode: "#c44bd2"
            },
        ]
    },

    {
        value: DefaultAnnotatableBodyPart["Left Waist"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Pelvis"],
                colorCode: "#57d028"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Knee"],
                colorCode: "#57d028"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Left Knee"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Waist"],
                colorCode: "#57d028"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Heel"],
                colorCode: "#57d028"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Left Heel"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Knee"],
                colorCode: "#57d028"
            },
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Toe"],
                colorCode: "#57d028"
            },
        ]
    },
    {
        value: DefaultAnnotatableBodyPart["Left Toe"],
        colorCode: "#00a817",
        connectionLines: [
            {
                connectedWith: DefaultAnnotatableBodyPart["Left Heel"],
                colorCode: "#57d028"
            },
        ]
    },
];

export const getDisplayParamsFromBodyPart = (bodyPart: string) =>
{
    const displayParams = defaultAnnotatableBodyPartsDisplayParams.filter(params => params.value === bodyPart);

    if (displayParams.length === 0 || displayParams.length >= 2)
        throw "Can't get display params for body part '" + bodyPart + "' : none or more than one exist with that name";

    return displayParams[0];
}