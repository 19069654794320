import {SSpatial2DDotAnnotation} from "@/store/Spatial2DAnnotation/model/SSpatial2DDotAnnotation";
import {
    Spatial2DDotAnnotationValidatorCenter
} from "@/store/Spatial2DAnnotation/validator/center/Spatial2DDotAnnotationValidatorCenter";
import {AbstractSubStoreManager} from "@/lib/store/manager/abstract/AbstractSubStoreManager";

export class Spatial2DDotAnnotationManager extends AbstractSubStoreManager<SSpatial2DDotAnnotation>
{
    protected getValidatorCenterForEntity = (entity: SSpatial2DDotAnnotation) => {
        return new Spatial2DDotAnnotationValidatorCenter(entity);
    };

    protected serve(entity: SSpatial2DDotAnnotation): SSpatial2DDotAnnotation {
        throw "On this project, annotations are inside bounding boxes and therefore, shouldn't be added this way, but only validated.";
    }
}