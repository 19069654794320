import DownloadHelper from "@/lib/common/helpers/DownloadHelper";
import {canvasToBlob, screenshotDomElementToCanvas} from "@/lib/common/utilities/DomUtilities";
import {BlobFile, createJsonBlobFile} from "@/lib/common/helpers/type/BlobFile";
import {completeFilename, removeExtensionFromFilename} from "@/lib/common/utilities/StringUtilities";
import {overlayCanvasOntoImage} from "@/lib/common/utilities/ImageUtilities";
import {createDownloadableResource, DownloadableResource} from "@/lib/common/helpers/type/DownloadableResource";
import OverlayableImage from "@/lib/components/frame/image/OverlayableImage.vue";
import {
    AnnotatableImagesStoreManager
} from "@/store/Spatial2DAnnotation/manager/AnnotatableImagesStoreManager";
import {SAnnotatableImageData} from "@/store/Spatial2DAnnotation/model/SAnnotatableImageData";

type CarouselImageType = {
    HTMLImage: HTMLImageElement,
}

class AnnotatableImagesDownloadHelper
{
    public async downloadAllAnnotatedImagesData(carouselImages: typeof OverlayableImage[])
    {
        const folderName = "mammal annotations";
        const zipName = new Date().toISOString() + " mammal annotations";

        const annotatedCowImagesData = AnnotatableImagesStoreManager.store.annotated;
        const annotatedImages = await this.prepareAnnotatedImages(annotatedCowImagesData, carouselImages);

        const downloadResources: DownloadableResource[] = [];

        for (const key in annotatedCowImagesData)
        {
            const mainDownloadableResources = await this.getAnnotatableImageDataBlobs(annotatedCowImagesData[key]);
            const annotatedImage = annotatedImages.filter(annotatedImage => annotatedImage.instanceId === annotatedCowImagesData[key].image.instanceId);

            mainDownloadableResources.forEach(mainDownloadableResource => {
                downloadResources.push(mainDownloadableResource);
            })

            annotatedImage.forEach(annotatedImage => {
                downloadResources.push(createDownloadableResource(annotatedImage.blobFile.blob, annotatedImage.blobFile.filename));
            })
        }

        return await DownloadHelper.zipAndDownloadResources(downloadResources, folderName, zipName);
    }

    protected async getAnnotatableImageDataBlobs(annotatableImageData: SAnnotatableImageData)
    {
        const resource: DownloadableResource[] = [];

        const jsonFilename = removeExtensionFromFilename(annotatableImageData.image.name) + ".json";

        resource.push(createDownloadableResource(annotatableImageData.image.file, annotatableImageData.image.file.name));
        resource.push(createDownloadableResource(createJsonBlobFile(annotatableImageData.boundingBoxes, jsonFilename).blob, jsonFilename));

        return resource;
    }

    protected async prepareAnnotatedImages(annotatableImagesData: SAnnotatableImageData[], carouselImages: typeof OverlayableImage[])
    {
        const imagesInstanceIds = annotatableImagesData.map(annotatableImageData => annotatableImageData.image.instanceId);

        const instanceIdedBlobFiles: {
            instanceId: string,
            blobFile: BlobFile
        }[] = [];

        // @ts-ignore don't trust webstorm on this one
        await carouselImages.reduce( async (previousPromise: typeof Promise, carouselImage: CarouselImageType) =>
        {
            // for each of them
            await previousPromise;

            const instanceId = carouselImage.HTMLImage.dataset.instanceId,
                name = carouselImage.HTMLImage.dataset.name,
                container = document.getElementById("overlayable-image-container-" + instanceId), // the container is the one being possibily unrendered, so we need to get it
                overlay = document.getElementById("image-overlay-" + instanceId); // refs don't seem to work properly, so using the good old method

            if (
                instanceId && name && overlay && container
                && imagesInstanceIds.includes(instanceId)
            )
            {
                // download a copy of their view when displayed
                const filename = completeFilename(name, "-annotated");

                const canvas: HTMLCanvasElement = await screenshotDomElementToCanvas(overlay, (clonedDoc: Document) =>
                {
                    // but before the download, assures this one is displayed in the cloned document (as they can be unrendered when clicking the download button)
                    Array.prototype.map.call(clonedDoc.getElementsByClassName(container.className), (element) => element.style.display = "none");
                    // @ts-ignore
                    clonedDoc.getElementById(container.id).style.display = 'block';
                    [...clonedDoc.getElementsByClassName("draggable-portions")].map((element: HTMLElement) => element.style.display = "none");
                    [...clonedDoc.getElementsByClassName("resizer")].map((element: HTMLElement) => element.style.display = "none");
                    [...clonedDoc.getElementsByClassName("delete-bounding-box")].map((element: HTMLElement) => element.style.display = "none");
                    [...clonedDoc.getElementsByClassName("annotations")].map((element: HTMLElement) => element.style.display = "block");
                });

                instanceIdedBlobFiles.push({
                    instanceId: instanceId,
                    blobFile: {
                        blob: await canvasToBlob(await overlayCanvasOntoImage(canvas, carouselImage.HTMLImage)),
                        filename: filename
                    }
                });
            }
        }, Promise.resolve())

        return instanceIdedBlobFiles;
    }
}

export default new AnnotatableImagesDownloadHelper();