import gsap from "gsap";
import { Draggable as DraggablePlugin } from "gsap/Draggable";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { Circ as CircAnim } from "gsap/gsap-core";

gsap.registerPlugin(DraggablePlugin);
gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(CircAnim)

export default gsap;

export const Draggable = DraggablePlugin; // allows drag and drop (possibly contained inside a parent)
export const DrawSVG = DrawSVGPlugin; // allows drawing shapes (instantly or progressively)

export const Circ = CircAnim;