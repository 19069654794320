import {AnnotationType} from "@/data/annotation/enum/AnnotationType";
import _ from "lodash";
import {toRaw} from "vue";
import {generateInstanceId} from "@/lib/common/utilities/StringUtilities";
import config from "@/config";
import {SSpatial2DBoundingBox} from "@/store/Spatial2DAnnotation/model/SSpatial2DBoundingBox";
import {
    Spatial2DBoundingBoxValidatorCenter
} from "@/store/Spatial2DAnnotation/validator/center/Spatial2DBoundingBoxValidatorCenter";
import {
    AnnotatableImagesStoreManager
} from "@/store/Spatial2DAnnotation/manager/AnnotatableImagesStoreManager";
import {SSpatial2DDotAnnotation} from "@/store/Spatial2DAnnotation/model/SSpatial2DDotAnnotation";
import {
    Spatial2DDotAnnotationManager
} from "@/store/Spatial2DAnnotation/manager/subManager/Spatial2DDotAnnotationManager";
import {AbstractValidatorCenter} from "@/lib/store/validator/center/abstract/AbstractValidatorCenter";
import {AbstractSubStoreManager} from "@/lib/store/manager/abstract/AbstractSubStoreManager";
import ValidationResult from "@/lib/store/validator/center/abstract/type/ValidationResult";
import {
    AnnotatableBodyPartDisplayParamsStoreManager
} from "@/store/Spatial2DAnnotation/manager/AnnotatableBodyPartDisplayParamsStoreManager";

export class Spatial2DBoundingBoxManager extends AbstractSubStoreManager<SSpatial2DBoundingBox>
{
    protected annotationTypeToValidate: string|undefined;
    protected maxInstanceForType: number|undefined;

    constructor(annotationTypeToValidate: string|void)
    {
        super();

        const maxInstancesPerBoundingBox = config.annotationTypes.maxInstancesPerBoundingBox;

        if (annotationTypeToValidate)
        {
            this.annotationTypeToValidate = annotationTypeToValidate;
            this.maxInstanceForType = annotationTypeToValidate in maxInstancesPerBoundingBox ? maxInstancesPerBoundingBox[annotationTypeToValidate] : 1;
        }
    }

    public createNew(type: AnnotationType): SSpatial2DBoundingBox
    {
        return {
            type: type,
            instanceId: generateInstanceId(),
            top: _.random(15, 40),
            left: _.random(15, 40),
            width: _.random(40, 60),
            height: _.random(40, 60),
            annotations: [],
        }
    }

    protected getValidatorCenterForEntity = (entity: SSpatial2DBoundingBox): AbstractValidatorCenter<SSpatial2DBoundingBox> => {
        return new Spatial2DBoundingBoxValidatorCenter<SSpatial2DBoundingBox>(entity, this.annotationTypeToValidate, this.maxInstanceForType);
    };

    public tryAddNew(type: AnnotationType): SSpatial2DBoundingBox
    {
        const result = this.validate(this.createNew(type));

        if (!result.isValid)
            throw result.errorMessages;

        const boundingBox = result.transformedEntity as SSpatial2DBoundingBox;

        AnnotatableImagesStoreManager.addBoundingBoxForCurrent(boundingBox)

        return boundingBox;
    }

    public addDotAnnotation = (boundingBox: SSpatial2DBoundingBox, dotAnnotation: SSpatial2DDotAnnotation) => {
        const rawBoundingBox = toRaw(boundingBox);

        rawBoundingBox.annotations.push(dotAnnotation);

        const result = this.validate(rawBoundingBox);

        if (!result.isValid)
            return result.errorMessages;

        const ValidatedBoundingBox = result.transformedEntity as SSpatial2DBoundingBox;

        AnnotatableImagesStoreManager.setCurrentBoundingBoxAnnotations(ValidatedBoundingBox.annotations);

        AnnotatableBodyPartDisplayParamsStoreManager.store.setIsSpawnedForSelected({isSpawned: true});
        AnnotatableBodyPartDisplayParamsStoreManager.store.switchToNextSelected();

        return ValidatedBoundingBox.annotations[ValidatedBoundingBox.annotations.length - 1];
    }

    public validate(entity: SSpatial2DBoundingBox): ValidationResult
    {
        const errors: string[] = [],
            annotations: SSpatial2DDotAnnotation[] = [];

        for ( const key in entity.annotations )
        {
            const annotation = entity.annotations[key],
                manager = new Spatial2DDotAnnotationManager(),
                validationResult = manager.validate(annotation);

            if (!validationResult.isValid)
                errors.concat(...validationResult.errorMessages);
            else
                annotations.push(validationResult.transformedEntity as SSpatial2DDotAnnotation);
        }

        entity.annotations = annotations;

        return super.validate(entity);
    }
}