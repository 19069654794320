import {Module, Mutation} from 'vuex-module-decorators'
import {AbstractStore} from "@/lib/store/holder/abstract/AbstractStore";
import {
  SAnnotatableBodyPartDisplayParamsData
} from "@/store/Spatial2DAnnotation/model/SAnnotatableBodyPartDisplayParamsData";
import {defaultAnnotatableBodyPartsDisplayParams} from "@/data/annotation/DefaultAnnotatableBodyPartsDisplayParams";
import {SSpatial2DBoundingBox} from "@/store/Spatial2DAnnotation/model/SSpatial2DBoundingBox";

@Module({
  name: "AnnotatableBodyPartDisplayParamsStore",
  namespaced: true,
  stateFactory: true
})

export class AnnotatableBodyPartDisplayParamsStore<D extends SAnnotatableBodyPartDisplayParamsData> extends AbstractStore<D>
{
  get selected()
  {
    return this._entries.filter(entry => entry.isSelected === true);
  }

  get byBodyPart()
  {
    const _this = this;

    return function (bodyPart: string, strict?: boolean): D|undefined
    {
      const entry = _this._entries.filter(entry => entry.value === bodyPart)
      const result = entry.length ? entry[0] : undefined;

      if (strict && typeof result === "undefined")
        throw "Can't find entry of bodyPart "+ bodyPart +" on strict mode";

      return result;
    }
  }

  @Mutation
  setIsSelected({instanceId, isSelected}: {instanceId: string, isSelected: boolean})
  {
    this._entries.forEach(entry => {
      entry.isSelected = false;
      if (entry.instanceId === instanceId)
        entry.isSelected = isSelected;
    });
  }

  @Mutation
  setIsSelectedBasedOnValue({value, isSelected}: {value: string, isSelected: boolean})
  {
    this._entries.forEach(entry => {
      entry.isSelected = false;
      if (entry.value === value)
        entry.isSelected = isSelected;
    });
  }

  @Mutation
  setIsSpawnedBasedOnValue({value, isSpawned}: {value: string, isSpawned: boolean})
  {
    this._entries.filter(entry => entry.value === value).map(entry => entry.isSpawned = isSpawned);
  }

  @Mutation
  setIsSpawnedForSelected({isSpawned}: {isSpawned: boolean})
  {
    this._entries.filter(entry => entry.isSelected).map(entry => entry.isSpawned = isSpawned);
  }

  @Mutation
  resetSelectedAndSpawned()
  {
    this._entries.forEach(entry => {
      entry.isSelected = false;
      entry.isSpawned = false;
    });
  }

  @Mutation
  resetSelected()
  {
    this._entries.forEach(entry => {
      entry.isSelected = false;
    });
  }

  @Mutation
  switchToNextSelected()
  {
    const selectedIndex = this._entries.findIndex(entry => entry.isSelected === true);

    const trySwitch = (testedEntryIndex: number, triedTimes: number = 1) =>
    {
      let nextOneIndex = testedEntryIndex + 1;

      if (nextOneIndex >= this._entries.length)
        nextOneIndex = 0;

      if (triedTimes > this._entries.length) {
        // if all entries have been tested, abort.
        this._entries.forEach(entry => {
          entry.isSelected = false;
        });
        return;
      }

      if (this._entries[nextOneIndex].isSpawned)
      {
        trySwitch(nextOneIndex, ++triedTimes)
      }
      else
      {
        this._entries.forEach(entry => {
          entry.isSelected = false;
        });

        this._entries[nextOneIndex].isSelected = true;
      }
    }

    trySwitch(selectedIndex >= 0 ? selectedIndex : (this._entries.length - 1))
  }

  @Mutation
  switchToPreviousSelected()
  {
    const selectedIndex = this._entries.findIndex(entry => entry.isSelected === true);

    const trySwitch = (testedEntryIndex: number, triedTimes: number = 1) =>
    {
      let nextOneIndex = testedEntryIndex - 1;

      if (nextOneIndex < 0)
        nextOneIndex = this._entries.length - 1;

      if (triedTimes > this._entries.length) {
        // if all entries have been tested, abort.
        this._entries.forEach(entry => {
          entry.isSelected = false;
        });
        return;
      }

      if (this._entries[nextOneIndex].isSpawned)
      {
        trySwitch(nextOneIndex, ++triedTimes)
      }
      else
      {
        this._entries.forEach(entry => {
          entry.isSelected = false;
        });

        this._entries[nextOneIndex].isSelected = true;
      }
    }

    trySwitch(selectedIndex >= 0 ? selectedIndex : (this._entries.length - 1))
  }

  public populate()
  {
    // Get wrapped parent module to access getters
    const base: any = Module({})(AbstractStore);
    // Copy parent getters to child
    Object.assign(AnnotatableBodyPartDisplayParamsStore.getters, base.getters);
    Object.assign(AnnotatableBodyPartDisplayParamsStore.mutations, base.mutations);
  }
}