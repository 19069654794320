import {createRouter, createWebHistory} from 'vue-router';
import CowImagesAnnotationView from "@/views/CowImagesAnnotation/CowImagesAnnotationView.vue";

const routes = [
    {
        path: '/',
        name: 'cow_images_annotation_view',
        component: CowImagesAnnotationView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router