import {uuid} from "vue-uuid";

export const completeFilename = (filename: string, add: string) =>
{
  const splitName = filename.split(".");

  if (splitName.length - 2 >= 0)
    splitName[splitName.length - 2] += add;
  else
    splitName[0] += add;

  return splitName.join(".");
}

export const generateInstanceId = () => {
  return uuid.v4();
}

export const spaceCamelCase = (camelCaseWord: string) => {
  return camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const removeExtensionFromFilename = (filename: string|String) => {
  return filename.replace(/\.[^/.]+$/, "")
}