import {getModule, Mutation} from "vuex-module-decorators";
import store from "@/lib/store";
import {
  defaultAnnotatableBodyPartsDisplayParams
} from "@/data/annotation/DefaultAnnotatableBodyPartsDisplayParams";
import {AbstractStoreManagerBuilder} from "@/lib/store/manager/abstract/AbstractStoreManager";
import {
  AnnotatableBodyPartDisplayParamsStore
} from "@/store/Spatial2DAnnotation/holder/AnnotatableBodyPartDisplayParamsStore";
import {
  SAnnotatableBodyPartDisplayParamsData
} from "@/store/Spatial2DAnnotation/model/SAnnotatableBodyPartDisplayParamsData";
import {SSpatial2DBoundingBox} from "@/store/Spatial2DAnnotation/model/SSpatial2DBoundingBox";

export class AnnotatableBodyPartDisplayParamsStoreManagerBuilder extends AbstractStoreManagerBuilder<AnnotatableBodyPartDisplayParamsStore<SAnnotatableBodyPartDisplayParamsData>, SAnnotatableBodyPartDisplayParamsData>
{
  protected _store: AnnotatableBodyPartDisplayParamsStore<SAnnotatableBodyPartDisplayParamsData> = getModule(AnnotatableBodyPartDisplayParamsStore, store);

  /**
   * Adds the default cat body part display params (used for menus and dot annotation creation
   */
  constructor()
  {
    super();

    this._store.reset();

    defaultAnnotatableBodyPartsDisplayParams.forEach(defaultAnnotatableBodyPartDisplayParams =>
    {
      this.add({
        instanceId: AbstractStoreManagerBuilder.createInstanceId(),
        ...defaultAnnotatableBodyPartDisplayParams
      });
    })
  }

  public resetBaseOnBoundingBox(BoundingBox: SSpatial2DBoundingBox)
  {
    this._store.resetSelectedAndSpawned();

    BoundingBox.annotations.map(annotation => {
      this._store.setIsSpawnedBasedOnValue({value: annotation.type, isSpawned: true});
    })

    this._store.switchToNextSelected();
  }
}

export const AnnotatableBodyPartDisplayParamsStoreManager = new AnnotatableBodyPartDisplayParamsStoreManagerBuilder();