export enum DefaultAnnotatableBodyPart {
    "Mouse" = "Mouse",
    "Head" = "Head",
    "Cervical Spine" = "Cervical Spine",
    "Pelvis" = "Pelvis",
    "Right Shoulder" = "Right Shoulder",
    "Right Elbow" = "Right Elbow",
    "Right Wrist" = "Right Wrist",
    "Right Finger" = "Right Finger",
    "Left Shoulder" = "Left Shoulder",
    "Left Elbow" = "Left Elbow",
    "Left Wrist" = "Left Wrist",
    "Left Finger" = "Left Finger",
    "Right Waist" = "Right Waist",
    "Right Knee" = "Right Knee",
    "Right Heel" = "Right Heel",
    "Right Toe" = "Right Toe",
    "Left Waist" = "Left Waist",
    "Left Knee" = "Left Knee",
    "Left Heel" = "Left Heel",
    "Left Toe" = "Left Toe",
}